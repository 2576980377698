import React, { Component } from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import Header from "../components/global/header"
import HeroBanner from "../components/holding-page/heroBanner"
import FloatingProducts from "../components/holding-page/floating-products"
import Instagram from "../components/global/instagram"
import { Element } from "react-scroll"
import ProductRange from "../components/holding-page/productRange"
import LinkBlock from "../components/holding-page/linkBlock"

class IndexPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const lastSunday = (month, year) => {
      const d = new Date()
      const lastDayOfMonth = new Date(
        Date.UTC(year || d.getFullYear(), month + 1, 0)
      )
      const day = lastDayOfMonth.getDay()
      return new Date(
        Date.UTC(
          lastDayOfMonth.getFullYear(),
          lastDayOfMonth.getMonth(),
          lastDayOfMonth.getDate() - day
        )
      )
    }

    const isBST = date => {
      const d = date || new Date()
      const starts = lastSunday(2, d.getFullYear())
      starts.setHours(1)
      const ends = lastSunday(9, d.getFullYear())
      starts.setHours(1)
      return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime()
    }

    let today = new Date()
    let britishSummerTime = isBST(today)

    return (
      <Layout
        noHeaderInLayout={true}
        makeFooterSticky={false}
        whiteMenu={!britishSummerTime ? true : false}
      >
        <SEO title="Home" />

        <div className="header-hero-container">
          <Header />
          <HeroBanner britishSummerTime={britishSummerTime} />
        </div>

        <FloatingProducts />

        <Element name="product-range" className="element"></Element>
        <div className="product-range" style={{ overflow: "hidden" }}>
          <ProductRange />
        </div>

        <Element className="instagram-container" name="instagram-container">
          <LinkBlock />
          {/* <Instagram /> */}
        </Element>
      </Layout>
    )
  }
}

export default IndexPage
