import React from "react"
import SingleFloatingProduct from "./single-floating-product"

import ultraPackaging from "../../images/product-packaging/packaging-ultra.svg"
import ultraProduct from "../../images/products/ultra-product.png"

import moisturiserCherryProduct from "../../images/products-without-packaging/moisturiser-cherry.png"
import moisturiserCherryPackaging from "../../images/product-packaging/packaging-moisturise-cherry.svg"

import medPlusPackaging from "../../images/product-packaging/packaging-medplus.svg"
import medPlusProduct from "../../images/products/medPlus-3.png"

import medPlusStickProduct from "../../images/products-without-packaging/medplus-stick.png"


import intensiveMoisturiserPackaging from "../../images/product-packaging/packaging-intensive-moisturiser.svg"
import intensiveMoisturiserProduct from "../../images/products-without-packaging/moisturiser.png"

import lipInfusionsNourishPackaging from "../../images/product-packaging/packaging-lip-infusions-nourish.svg"
import lipInfusionsNourishProduct from "../../images/products/lip-infusions-nourish.png"

import lipInfusionsSoothingPackaging from "../../images/product-packaging/packaging-lip-infusions-soothing.svg"
import lipInfusionsSoothingProduct from "../../images/products/lip-infusions-soothing.png"

import lipInfusionsHydrationPackaging from "../../images/product-packaging/packaging-lip-infusions-hydration.svg"
import lipInfusionsHydrationProduct from "../../images/products/lip-infusions-hydration.png"

import lipInfusionsRestorePackaging from "../../images/product-packaging/packaging-lip-infusions-restore.svg"
import lipInfusionsRestoreProduct from "../../images/products/lip-infusions-restore.png"

import chevronDown from "../../images/icons/chevron-down.svg"

/*
  NOTE:
  The JS for the floating products is called in the scroll event listener in productRange.js
*/

const FloatingProducts = ({ scrollDirection }) => {
  const products = {
    product: {
      productTitle: "Intensive Moisturiser",
      productImage: intensiveMoisturiserProduct,
      packaging: intensiveMoisturiserPackaging,
      productLink: "/intensive-moisturiser",
    },
    productTwo: {
      productTitle: "Intensive Moisturiser Cherry",
      productImage: moisturiserCherryProduct,
      packaging: moisturiserCherryPackaging,
      productLink: "/intensive-moisturiser-cherry",
    },
    productThree: {
      productTitle: "MedPlus Jar",
      productImage: medPlusProduct,
      packaging: medPlusPackaging,
      productLink: "/medplus",
    },
    productFour: {
      productTitle: "MedPlus Stick",
      productImage: medPlusStickProduct,
      packaging: medPlusPackaging,
      productLink: "/medplus-stick",
    },
    productFive: {
      productTitle: "Lip Infusions - Restore",
      productImage: lipInfusionsRestoreProduct,
      packaging: lipInfusionsRestorePackaging,
      productLink: "/lip-infusions-restore",
    },
    productSix: {
      productTitle: "Lip Infusions - Hydration",
      productImage: lipInfusionsHydrationProduct,
      packaging: lipInfusionsHydrationPackaging,
      productLink: "/lip-infusions-hydration",
    },
    productSeven: {
      productTitle: "Lip Infusions - Soothing",
      productImage: lipInfusionsSoothingProduct,
      packaging: lipInfusionsSoothingPackaging,
      productLink: "/lip-infusions-soothing",
    },
    productEight: {
      productTitle: "Lip Infusions - Nourish",
      productImage: lipInfusionsNourishProduct,
      packaging: lipInfusionsNourishPackaging,
      productLink: "/lip-infusions-nourish",
    },
  }
  return (
    <div className="floating-products-container">
      <div className="floating-products container-left container-right">
        <SingleFloatingProduct
          data={products.product}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productTwo}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productThree}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productFour}
          scrollDirection={scrollDirection}
        />
      </div>
      <div className="floating-products container-left container-right">
        <SingleFloatingProduct
          data={products.productFive}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productSix}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productSeven}
          scrollDirection={scrollDirection}
        />
        <SingleFloatingProduct
          data={products.productEight}
          scrollDirection={scrollDirection}
        />
      </div>
      <div className="product-range__introduction">
        <div className="product-range__section-title">
          <h2>the Blistex range</h2>
        </div>
        <div className="product-range__mobile-scroll">
          <p>Scroll to explore</p>
          <img src={chevronDown} alt="" />
        </div>
      </div>
    </div>
  )
}

export default FloatingProducts
