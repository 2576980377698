import React, { Component } from "react"
import SingleProduct from "./single-product"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { Link } from "gatsby"
import disableScroll from "../lib/disableScroll"

import Div100vh from "react-div-100vh"

//Infusions
import lipInfusionsNourishPackaging from "../../images/product-packaging/lip-infusions-nourish-bleed.svg"
import lipInfusionsNourishProduct from "../../images/products/lip-infusions-nourish-large.png"
import lipInfusionsNourishText from "../../images/product-text/Infusions-nourish-text-large.svg"

import lipInfusionsSoothingPackaging from "../../images/product-packaging/lip-infusions-soothing-bleed.svg"
import lipInfusionsSoothingProduct from "../../images/products/lip-infusions-soothing-large.png"
import lipInfusionsSoothingText from "../../images/product-text/Infusions-soothing-text-Large.svg"

import lipInfusionsHydrationPackaging from "../../images/product-packaging/lip-infusions-hydration-bleed.svg"
import lipInfusionsHydrationProduct from "../../images/products/lip-infusions-hydration-large.png"
import lipInfusionsHydrationText from "../../images/product-text/Infusions-hydration-text-Large.svg"

import lipInfusionsRestorePackaging from "../../images/product-packaging/lip-infusions-restore-bleed.svg"
import lipInfusionsRestoreProduct from "../../images/products/lip-infusions-restore-large.png"
import lipInfusionsRestoreText from "../../images/product-text/Infusions-restore-text-Large.svg"
//

//Core Range
import coldSorePackaging from "../../images/new/product-packaging/cold-sore-cream-packaging.svg"
import coldSoreText from "../../images/new/product-packaging/cold-sore-cream-text-2.png"

import lipBrilliancePackaging from "../../images/new/product-packaging/lip-brilliance-packaging.svg"
import lipBrillianceText from "../../images/new/product-packaging/text-lb.png"
import moisturiseCherryPackaging from "../../images/new/product-packaging/moisturise-cherry-packaging-2.svg"

import moisturiseCherryText from "../../images/new/product-packaging/text-imc.png"

import moisturisePackaging from "../../images/new/product-packaging/intensive-moisturiser-packaging.svg"
import moisturiseText from "../../images/new/product-packaging/text-im.png"

import reliefCreamPackaging from "../../images/new/product-packaging/relief-cream-packaging.svg"
import reliefCreamText from "../../images/new/product-packaging/relief-cream-text-2.png"

import medPlusPackaging from "../../images/new/product-packaging/medplus-packaging.svg"
import medPlusText from "../../images/new/product-packaging/text-medplus.png"

import ultraPackaging from "../../images/new/product-packaging/ultra-packaging.svg"
import ultraText from "../../images/new/product-packaging/text-ultra.png"

import coldSoreProduct from "../../images/new/single-products/cold-sore-cream-product.png"
import lipBrillianceProduct from "../../images/new/single-products/lip-brilliance-product-2.png"
import moisturiseCherryProduct from "../../images/new/single-products/moisturiser-cherry-product.png"
import moisturiseProduct from "../../images/new/single-products/moisturiser-product.png"
import reliefCreamProduct from "../../images/new/single-products/relief-cream-product.png"
import medPlusProduct from "../../images/new/single-products/medplus-product.png"
import ultra from "../../images/new/single-products/ultra-product.png"

import coldIcon from "../../images/icons/product-icon/winter.svg"
import everydayIcon from "../../images/icons/product-icon/everyday.svg"
import outdoorIcon from "../../images/icons/product-icon/outdoors.svg"
import overnightIcon from "../../images/icons/product-icon/overnight.svg"
import partyIcon from "../../images/icons/product-icon/party.svg"
import sunIcon from "../../images/icons/product-icon/sun.svg"

import chevronDown from "../../images/icons/chevron-down.svg"
import plus from "../../images/icons/plus.svg"

import ReactPageScroller from "../lib/reactPageScroller"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import uuid from "uuid/v1"

const iconImages = {
  coldweather: coldIcon,
  everyday: everydayIcon,
  outdoors: outdoorIcon,
  atnight: overnightIcon,
  party: partyIcon,
  sunnyweather: sunIcon,
}
const arr = [
  // {
  //   id: uuid(),
  //   classes: "double",
  //   packaging: reliefCreamPackaging,
  //   text: reliefCreamText,
  //   description:
  //     "soothing and protective formula to relieve cracked, chapped and sore lips leaving them feeling soft again.",
  //   name: "Blistex Relief Cream.",
  //   icons: ["outdoors", "cold-weather", "at-night"],
  //   link: "/blistex-relief-cream",
  //   disclaimer: (
  //     <p style={{ marginTop: "10px" }}>
  //       <strong>
  //         Blistex Relief Cream is a medicine for chapped lips. always read the
  //         label.
  //       </strong>
  //     </p>
  //   ),
  // },
  // {
  //   id: uuid(),
  //   classes: "double",
  //   productType: "standard",
  //   packaging: coldSorePackaging,
  //   text: coldSoreText,
  //   description:
  //     "suppress the cold sore. starts to work from first tingle. aids healing.",
  //   name: "Cold Sore Cream.",
  //   link: "/cold-sore-cream",
  //   disclaimer: (
  //     <p style={{ marginTop: "10px" }}>
  //       <strong>
  //         Blistex Cold Sore Cream contains docosanol. always read the label.
  //       </strong>
  //     </p>
  //   ),
  // },
  {
    id: uuid(),
    classes: "double",
    productType: "standard",
    packaging: moisturisePackaging,
    text: moisturiseText,
    icons: ["everyday", "outdoors", "cold-weather", "at-night"],
    description:
      "Blistex Intensive Moisturiser is infused with a blend of rich moisturisers like Shea Butter to condition, Camphor to soothe and Allantoin to smooth. It moisturises deep down to intensely rehydrate your lips.",
    name: "Intensive Moisturiser.",
    link: "/intensive-moisturiser",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "single",
    productType: "standard",
    packaging: medPlusPackaging,
    text: medPlusText,
    icons: ["outdoors", "cold-weather"],
    description: "Blistex MedPlus Jar is perfect when lips need some TLC.",
    name: "MedPlus Jar.",
    link: "/medplus",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "double",
    productType: "standard",
    packaging: moisturiseCherryPackaging,
    text: moisturiseCherryText,
    icons: ["everyday", "outdoors", "cold-weather", "at-night"],
    description:
      "Blistex Intensive Moisturiser Cherry is infused with a blend of rich moisturisers like Shea Butter to condition and Allantoin to smooth. it moisturises deep down to intensely hydrate your lips, with a hint of cherry.",
    name: "Intensive Moisturiser Cherry.",
    link: "/intensive-moisturiser-cherry",
    disclaimer: "",
  },
  // {
  //   id: uuid(),
  //   classes: "double",
  //   productType: "standard",
  //   packaging: lipBrilliancePackaging,
  //   text: lipBrillianceText,
  //   icons: ["everyday", "party"],
  //   description:
  //     "Blistex Lip Brilliance gives your lips a rosy blush whilst enriching them with Silk Extracts for softness, Grape Seed Oil to condition and Hyaluronic Filling Spheres to boost their natural fullness, so they look healthy, full and fabulous.",
  //   name: "Lip Brilliance.",
  //   link: "/lip-brilliance",
  //   disclaimer: "",
  // },
  {
    id: uuid(),
    classes: "double",
    productType: "standard",
    packaging: ultraPackaging,
    text: ultraText,
    icons: ["everyday", "outdoors", "sunny-weather", "cold-weather"],
    description:
      "Blistex Ultra SPF 50+ provides very high sun protection to help defend your lips from UVA and UVB rays. Infused with antioxidant vitamins C & E, it helps seal in moisture to keep them hydrated, whilst protecting them from the sun.",
    name: "Ultra Lip Balm.",
    link: "/ultra-lip-balm",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "double",
    productType: "infusions",
    packaging: lipInfusionsRestorePackaging,
    text: lipInfusionsRestoreText,
    icons: ["everyday", "outdoors", "cold-weather", "at-night"],
    description:
      "Advanced lip care formula infused with a blend of ingredients that restores lips' moisture and leaves them feeling naturally soft and smooth again.",
    name: "Lip Infusions - Restore.",
    link: "/lip-infusions-restore",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "double",
    productType: "infusions",
    packaging: lipInfusionsHydrationPackaging,
    text: lipInfusionsHydrationText,
    icons: ["everyday", "outdoors", "cold-weather", "at-night"],
    description:
      "Long-lasting lip care formula provides perfectly balanced hydration to drench your lips in moisture without a heavy, greasy feel and is clinically shown to moisture for up to 24 hours.",
    name: "Lip Infusions - Hydration.",
    link: "/lip-infusions-hydration",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "double",
    productType: "infusions",
    packaging: lipInfusionsSoothingPackaging,
    text: lipInfusionsSoothingText,
    icons: ["everyday", "at-night"],
    description:
      "Advanced lip care formula that is gentle on your lips but tough on dryness, to soothe irritated lips and keep them soft, smooth and conditioned with maximum comfort.",
    name: "Lip Infusions - Soothing.",
    link: "/lip-infusions-soothing",
    disclaimer: "",
  },
  {
    id: uuid(),
    classes: "double",
    productType: "infusions",
    packaging: lipInfusionsNourishPackaging,
    text: lipInfusionsNourishText,
    icons: ["everyday", "outdoors", "cold-weather", "at-night"],
    description:
      "Advanced lip care formula delivers concentrated nourishment for luxuriously smooth lips. Infused with a blend of nutrient-enriched botanical oils and Vitamin E to soften without leaving a greasy feel.",
    name: "Lip Infusions - Nourish.",
    link: "/lip-infusions-nourish",
    disclaimer: "",
  },
]

//let sw = true
let timeout

class ProductRange extends Component {
  _timeoutId = 0
  constructor(props) {
    super(props)
    this.state = {
      selected: arr[0],
      loaded: false,
      disableScroll: true,
      noScroll: false,
      currentPage: 1,
      productsRangeOffset: "",
      heroPos: "",
      productsPos: "",
      instagramPos: "",
      footerPos: "",
      scrollDirection: "",
      prev: 0,
      packagingOriginalBottomPos: "",
      productOriginalBottomPos: "",
      windowWidth: 0,
      windowHeight: 0,
      navigationOpen: false,
      xDown: null,
      yDown: null,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.handleFloatingProducts = this.handleFloatingProducts.bind(this)
    this.pageOnChange = this.pageOnChange.bind(this)
    this.nextImage = this.nextImage.bind(this)
    this.scrollUnavailable = this.scrollUnavailable.bind(this)
    this.toggleNavigation = this.toggleNavigation.bind(this)
  }

  initComponent = () => {
    this._sw = true
    const fproducts = document.querySelector(".floating-products-container")
    const prange = document.querySelector(".blistex-range__product")
    prange.setAttribute("data-product-scroll", "false")
    if (fproducts && prange) {
      window.addEventListener("scroll", e => this.handleScroll(e))
      window.addEventListener("resize", () => this.handleFloatingProducts(true))
    }

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })

    const instagram = document.querySelector(".instagram-container")
    const footer = document.querySelector(".blistex-footer")
    let instagramPos
    let footerPos
    if (instagram) {
      instagramPos = instagram.offsetTop
      instagram.style.position = "fixed"
      instagram.style.top = instagramPos + "px"
    }

    if (footer) {
      footerPos = footer.offsetTop
      footer.style.position = "fixed"
      footer.style.top = footerPos + "px"
    }

    window.setTimeout(() => {
      this.setState({ loaded: true })
    }, 600)

    // if (
    //   document.querySelector("body").classList.contains("rps-scroll--disabled")
    // ) {
    //   console.log("about to fix elem")
    //   this.fixElem()
    // }
  }

  componentDidMount() {
    this._isMounted = true
    this._timeoutId = window.setTimeout(() => {
      // if (
      //   document
      //     .querySelector("body")
      //     .classList.contains("rps-scroll--disabled")
      // ) {
      //   this.setState({ disableScroll: false })
      //   const elemCont = document.querySelector(".product-range")
      //   disableBodyScroll(elemCont)
      //   disableScroll.on()
      // }

      this.initComponent()
    }, 600)
    clearAllBodyScrollLocks()
    disableScroll.off()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("resize", this.handleFloatingProducts)
    clearAllBodyScrollLocks()
    disableScroll.off()
    clearTimeout(timeout)
    this._isMounted = false
    clearTimeout(this._timeoutId)
  }

  handleFloatingProducts(isResize = false) {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
    const container = document.querySelector(".floating-products-container")
    if (!container) return
    if (isResize === true) container.removeAttribute("data-offset")

    if (container.hasAttribute("data-offset")) return

    const floatingProducts = document.querySelectorAll(
      ".floating-products__single-product"
    )

    floatingProducts.forEach(product => {
      const productHeight = product.querySelector("img").offsetHeight + 5
      product.style.top = "calc(100vh - " + productHeight + "px)"
    })
    container.setAttribute("data-offset", "true")
  }

  handleKeyPress = e => {
    if (e.key === "ArrowDown" || e.key === "Down" || e.code === "ArrowDown") {
      if (parseInt(this.state.currentPage) === arr.length) {
        this.removeFixedElem()
        return
      }

      if (parseInt(this.state.currentPage) > 0) {
        this.goToPage(parseInt(this.state.currentPage))
      }
    }
    if (e.key === "ArrowUp" || e.key === "Up" || e.code === "ArrowUp") {
      if (parseInt(this.state.currentPage) === 1) {
        this.removeFixedElem()
        return
      }

      if (parseInt(this.state.currentPage) <= arr.length) {
        this.goToPage(parseInt(this.state.currentPage) - 2)
      }
    }
  }

  fixElem = () => {
    this.setState({ disableScroll: false })
    const elemCont = document.querySelector(".product-range")
    if (
      elemCont.hasAttribute("scrolling") &&
      elemCont.getAttribute("scrolling") === "scrolling"
    ) {
      return
    }

    const elem = document.querySelector(".blistex-range__product")

    // The below attribute is checked for (and later set) to prevent this function from being called multiple times
    if (
      elem.hasAttribute("data-scroll-disabled") &&
      elem.getAttribute("data-scroll-disabled") === "true"
    ) {
      return
    }
    var ua = window.navigator.userAgent
    var msie = ua.indexOf("MSIE ")

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    } else {
      const stickyFloatingElems = document.querySelectorAll(
        ".floating-products__single-product--stick"
      )
      if (stickyFloatingElems.length > 0) {
        stickyFloatingElems.forEach(elem => {
          elem.classList.remove("floating-products__single-product--stick")
        })
      }
    }

    // Scroll the top of the element into view and disable scroll
    elemCont.scrollIntoView(true)
    disableBodyScroll(elemCont)
    disableScroll.on()
    elem.setAttribute("data-scroll-disabled", "true")
    elemCont.classList.add("active")
    elemCont.style.zIndex = "9"

    const overlay = document.querySelector(".overlay")
    const stockistsCheckbox = document.getElementById("stockists")

    window.addEventListener("keydown", this.handleKeyPress, false)

    if (stockistsCheckbox.checked) {
      stockistsCheckbox.checked = !stockistsCheckbox.checked
      overlay.classList.remove("overlay--show")
    }
  }

  removeFixedElem = () => {
    const elemCont = document.querySelector(".product-range")
    const elem = document.querySelector(".blistex-range__product")
    if (
      elem.hasAttribute("data-scroll-disabled") &&
      elem.getAttribute("data-scroll-disabled") === "false"
    )
      return

    // Below is a fix for a weird Sticky Fix bug, as position sticky doesn't work on IE this is only required on other browsers
    var ua = window.navigator.userAgent
    var msie = ua.indexOf("MSIE ")

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    } else {
      const stickyFloatingElems = document.querySelectorAll(
        ".floating-products__single-product"
      )
      if (stickyFloatingElems.length > 0) {
        stickyFloatingElems.forEach(elem => {
          elem.classList.add("floating-products__single-product--stick")
        })
      }
    }

    window.removeEventListener("keydown", this.handleKeyPress, false)
    const sectionTitle = document.querySelector(".product-range__section-title")
    sectionTitle.style.zIndex = ""

    enableBodyScroll(elemCont)
    disableScroll.off()

    this.setState({ disableScroll: true })
    elem.setAttribute("data-scroll-disabled", "false")
    elemCont.classList.remove("active")
    elemCont.style.zIndex = "-1"
    document.querySelector(".blistex-range__product").opacity = 1
  }

  handleScroll = e => {
    if (!this._isMounted) return
    const fproducts = document.querySelector(".floating-products-container")
    const prange = document.querySelector(".blistex-range__product")
    if (
      document.querySelector(".product-range").hasAttribute("scrolling") &&
      document.querySelector(".product-range").getAttribute("scrolling") ===
        "scrolling"
    ) {
      return
    }

    if (!fproducts && !prange) return
    if (this._sw) {
      this._sw = false
      timeout = setTimeout(() => {
        this._sw = true
        var windowTop =
          window.scrollY ||
          window.pageYOffset ||
          document.body.scrollTop +
            ((document.documentElement && document.documentElement.scrollTop) ||
              0)

        if (
          prange.hasAttribute("scrolling") &&
          prange.getAttribute("scrolling") === "finished"
        ) {
          windowTop = 0
        }
        const hero = document.querySelector(".header-hero-container")
        const products = document.querySelector(".floating-products")
        const instagram = document.querySelector(".instagram-container")
        const footer = document.querySelector(".blistex-footer")
        let heroPos
        let productsPos
        let instagramPos
        let footerPos

        if (hero) heroPos = hero.offsetTop
        if (products) productsPos = products.offsetTop
        if (instagram) instagramPos = instagram.offsetTop
        if (footer) footerPos = footer.offsetTop

        this.handleFloatingProducts(windowTop)

        if (!prange) return

        if (this.state.productsRangeOffset === "")
          this.setState({
            productsRangeOffset:
              fproducts.offsetTop + fproducts.offsetHeight - 1,
          })

        if (this.state.heroPos === "") this.setState({ heroPos: heroPos })

        if (this.state.productsPos === "")
          this.setState({ productsPos: productsPos })

        if (this.state.instagramPos === "")
          this.setState({ instagramPos: instagramPos })

        if (this.state.footerPos === "") this.setState({ footerPos: footerPos })

        if (
          windowTop + this.state.windowHeight <
          this.state.productsRangeOffset
        )
          return

        // Determine scroll direction
        if (this.state.prev > windowTop) {
          this.setState({
            scrollDirection: "up",
          })
        } else if (
          this.state.prev < windowTop &&
          this.state.disableScroll === true
        ) {
          this.setState({
            scrollDirection: "down",
          })
        }

        if (
          !document.querySelector(".product-range").classList.contains("active")
        ) {
          let prangeOpacity =
            0.3 +
            (windowTop - this.state.productsRangeOffset / 2) /
              this.state.windowHeight

          prange.style.opacity = prangeOpacity
        }

        // If scroll position is greater than or equal to the top of the product range section and we haven't scrolled to the final page in the carousel
        if (
          windowTop >= this.state.productsRangeOffset &&
          this.state.currentPage < arr.length
        ) {
          this.fixElem()
          this.setState({ prev: windowTop + 1 })
        } else if (
          this.state.currentPage === arr.length &&
          windowTop <= this.state.productsRangeOffset &&
          this.state.scrollDirection === "up"
        ) {
          // If we are at the end of the page carousel but are scrolling back up, then refix the element
          this.fixElem()
        }

        this.setState({ prev: windowTop })
      }, 200)
    }
  }

  nextImage = number =>
    this.setState(() => {
      return {
        index: number,
        selected: arr[number],
      }
    })

  pageOnChange = number => {
    const hero = document.querySelector(".header-hero-container")
    const products = document.querySelector(".floating-products-container")
    const prange = document.querySelector(".blistex-range__product")
    const instagram = document.querySelector(".instagram-container")
    const footer = document.querySelector(".blistex-footer")

    if (number > 1) {
      hero.classList.remove("active")
      products.classList.remove("active")
      // Commented the below out as it was preventing the stockists popup from showing over the top of the product range pages
      hero.style.opacity = "0"
      products.style.opacity = "0"
      prange.style.opacity = "1"
    } else {
      hero.classList.add("active")
      products.classList.add("active")
      hero.style.opacity = "1"
      products.style.opacity = "1"
    }

    if (number === arr.length) {
      instagram.classList.add("active")
      instagram.style.position = "relative"
      instagram.style.top = "0px"
      footer.classList.add("active")
      footer.style.position = "relative"
      footer.style.top = "0px"
      instagram.style.opacity = "1"
      footer.style.opacity = "1"
    } else {
      instagram.classList.remove("active")
      instagram.style.position = "fixed"
      instagram.style.top = this.state.instagramPos + "px"
      footer.classList.remove("active")
      footer.style.position = "fixed"
      footer.style.top = this.state.footerPos + "px"
      instagram.style.opacity = "0"
      footer.style.opacity = "0"
    }

    document.documentElement.scrollTop = document.body.scrollTop = this.state.productsRangeOffset

    if (number < this.state.currentPage) {
      this.setState({ scrollDirection: "up" })
    } else if (number > this.state.currentPage) {
      this.setState({ scrollDirection: "down" })
    }
    this.setState({ currentPage: number, disableScroll: false, noScroll: true })
    this.nextImage(number - 1)

    // Change the Product Range Carousel navigation
    this.setState({ navigationOpen: false })
    const showNameClass = "blistex-range__navigation-item-name--active"
    const showCircleClass = "circle--active"
    const showLine = "line--draw"
    const navigation = document.querySelectorAll(
      ".blistex-range__navigation-item"
    )
    const currentNav = navigation[number - 1]

    if (
      document.querySelector(`.${showNameClass}`) &&
      document.querySelector(`.${showNameClass}`).classList
    ) {
      document
        .querySelector(`.${showNameClass}`)
        .classList.remove(showNameClass)
    }

    if (
      document.querySelector(`.${showCircleClass}`) &&
      document.querySelector(`.${showCircleClass}`).classList
    ) {
      document
        .querySelector(`.${showCircleClass}`)
        .classList.remove(showCircleClass)
    }

    if (
      document.querySelector(`.${showLine}`) &&
      document.querySelector(`.${showLine}`).classList
    ) {
      document.querySelector(`.${showLine}`).classList.remove(showLine)
    }

    if (currentNav.querySelector(".blistex-range__navigation-item-name")) {
      currentNav
        .querySelector(".blistex-range__navigation-item-name")
        .classList.add(showNameClass)
    }

    if (currentNav.querySelector(".circle"))
      currentNav.querySelector(".circle").classList.add(showCircleClass)

    if (currentNav.querySelector(".line"))
      currentNav.querySelector(".line").classList.add(showLine)
  }

  goToPage = pageNumber => {
    this.reactPageScroller.goToPage(pageNumber)
  }

  toggleNavigation() {
    this.setState({ navigationOpen: !this.state.navigationOpen })
  }

  scrollUnavailable() {
    document.querySelector(".product-range__section-title").style.position = ""
    document.querySelector(".product-range__section-title").style.zIndex = ""
    document.querySelector(".product-range__section-title").style.visibility =
      ""
    document.querySelector(".background-packaging").style.zIndex = ""
    this.removeFixedElem()
    this.setState({ disableScroll: true, noScroll: true })
    const prange = document.querySelector(".blistex-range__product")
    prange.setAttribute("data-product-scroll", "false")
  }

  render() {
    const navItems = arr.map((item, i) => (
      <div
        key={i}
        className="blistex-range__navigation-item"
        onClick={() => {
          this.goToPage(i)
        }}
      >
        <span
          className={
            i === 0
              ? "blistex-range__navigation-item-name blistex-range__navigation-item-name--active"
              : "blistex-range__navigation-item-name"
          }
        >
          {item.name}
        </span>
        <svg>
          <circle
            className={i === 0 ? "circle circle--active" : "circle"}
            cx="15"
            cy="15"
            r="14"
            strokeDasharray="1000"
          />
        </svg>
        <div className="line-container">
          <div className={i === 0 ? "line line--draw" : "line"}></div>
        </div>
      </div>
    ))

    return (
      <>
        <Div100vh>
          <div
            id="range"
            className="blistex-range"
            style={{ position: "relative" }}
          >
            <div className="blistex-range__product--phantom"></div>
            <div className="blistex-range__product">
              {this.state.windowWidth > 1199 && (
                <div
                  className={
                    "product-range__section-title" +
                    (this.state.loaded === true ? " loaded" : "")
                  }
                >
                  <h2>
                    <Link to="/products/">the Blistex range</Link>
                  </h2>
                </div>
              )}
              <TransitionGroup>
                <CSSTransition
                  key={this.state.selected.id}
                  timeout={1000}
                  classNames="cross-fade"
                >
                  {/* The background packaging and text */}
                  <div className="blistex-range__packaging">
                    <div
                      className={`blistex-range__packaging-container ${this.state.selected.productType}`}
                    >
                      <img
                        className="background-packaging"
                        src={this.state.selected.packaging}
                        alt=""
                      />
                      <div className="background-packaging-text-container">
                        <img
                          className="background-packaging-text"
                          src={this.state.selected.text}
                          alt={this.state.selected.name}
                        />
                      </div>
                      <div
                        className={`background-packaging-description ${this.state.selected.classes}`}
                      >
                        <span className="product-icon__container">
                          {arr[this.state.currentPage - 1].icons &&
                            arr[this.state.currentPage - 1].icons.map(
                              (icon, index) => (
                                <span
                                  className={"product-icon " + icon}
                                  key={index}
                                >
                                  <img
                                    src={iconImages[icon.replace("-", "")]}
                                    alt={icon.replace("-", " ")}
                                  />
                                  <span className="product-icon__tooltip">
                                    {icon.replace("-", " ")}
                                  </span>
                                </span>
                              )
                            )}
                        </span>
                        <p>{this.state.selected.description}</p>
                        {arr[this.state.currentPage - 1].disclaimer}
                        {/* <a
                        className="single-product__stockist-link"
                        href={arr[this.state.currentPage - 1].link}
                        // onClick={toggleStockists}
                      > */}
                        <Link
                          to={arr[this.state.currentPage - 1].link}
                          className="single-product__stockist-link"
                        >
                          <img
                            src={plus}
                            alt="Click to view product"
                            style={{
                              width: "14px",
                              height: "14px",
                              marginRight: "10px",
                            }}
                          />
                          view product
                        </Link>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
              {/* The individual single products that scroll up as you scroll through */}
              <ReactPageScroller
                ref={c => (this.reactPageScroller = c)}
                // animationTimer={800}
                scrollUnavailable={this.scrollUnavailable}
                pageOnChange={this.pageOnChange}
                goToPage={this.goToPage}
                containerWidth={"100%"}
                containerHeight={"100%"}
                blockScrollUp={this.state.disableScroll}
                blockScrollDown={this.state.disableScroll}
              >
                {/* <SingleProduct
                  key="single-product-11"
                  title="Blistex relief cream."
                  image={reliefCreamProduct}
                  link="/blistex-relief-cream"
                  showBuyButton={false}
                />
                <SingleProduct
                  key="single-product-10"
                  title="Cold sore cream"
                  image={coldSoreProduct}
                  link="/cold-sore-cream"
                  showBuyButton={true}
                /> */}
                <SingleProduct
                  key="single-product-4"
                  title="intensive moisturiser."
                  image={moisturiseProduct}
                  link="/intensive-moisturiser"
                  showBuyButton={true}
                />

                <SingleProduct
                  key="single-product-5"
                  title="MedPlus Jar"
                  image={medPlusProduct}
                  link="/medplus"
                  showBuyButton={true}
                />
                <SingleProduct
                  key="single-product-6"
                  title="Intensive moisturiser cherry"
                  image={moisturiseCherryProduct}
                  link="/intensive-moisturiser-cherry"
                  showBuyButton={true}
                />
                {/* <SingleProduct
                  key="single-product-7"
                  title="Lip brilliance"
                  image={lipBrillianceProduct}
                  link="lip-brilliance"
                  showBuyButton={true}
                /> */}
                <SingleProduct
                  key="single-product-8"
                  title="Ultra lip balm"
                  image={ultra}
                  link="/ultra-lip-balm"
                  showBuyButton={true}
                />
                <SingleProduct
                  key="single-product-9"
                  title="Lip Infusions - Restore."
                  image={lipInfusionsRestoreProduct}
                  link="/lip-infusions-restore"
                  showBuyButton={true}
                />
                <SingleProduct
                  key="single-product-2"
                  title="Lip Infusions - Hydration."
                  image={lipInfusionsHydrationProduct}
                  link="/lip-infusions-hydration"
                  showBuyButton={true}
                />
                <SingleProduct
                  key="single-product-3"
                  title="Lip Infusions - Soothing."
                  image={lipInfusionsSoothingProduct}
                  link="/lip-infusions-soothing"
                  showBuyButton={true}
                />
                <SingleProduct
                  key="single-product-1"
                  title="Lip Infusions - Nourish."
                  image={lipInfusionsNourishProduct}
                  link="/lip-infusions-nourish"
                  showBuyButton={true}
                />
              </ReactPageScroller>

              {/* Page carousel navigation */}
              <nav
                className={
                  "blistex-range__navigation" +
                  (this.state.navigationOpen
                    ? " blistex-range__navigation--open"
                    : "")
                }
                onClick={this.toggleNavigation}
              >
                <div className="blistex-range__navigation__mobile-icon-container">
                  <img
                    src={chevronDown}
                    className={
                      "blistex-range__navigation__mobile-icon" +
                      (this.state.navigationOpen
                        ? " blistex-range__navigation__mobile-icon--open"
                        : "")
                    }
                    alt=""
                  />
                </div>
                <h3 className="blistex-range__navigation__title">
                  <a href="/products">our range</a>
                </h3>
                {navItems}
              </nav>
            </div>
          </div>
        </Div100vh>
      </>
    )
  }
}

export default ProductRange
