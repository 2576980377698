import React, { Component } from "react"
import Sticker from "react-stickyfill"

class SingleFloatingProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      atBottom: false,
      positionClass: "",
      productLeftPosition: "",
      positionFixed: false,
      packagingBottom: 0,
      productOriginalTopPos: "",
      productAbsoluteBottomPos: "",
    }
    this.floatingProduct = React.createRef()
    this.packagingNode = React.createRef()
  }
  render() {
    const {
      productTitle,
      productImage,
      packaging,
      productLink,
    } = this.props.data
    let styles = {
      left: this.state.productLeftPosition,
    }

    return (
      <div className="floating-products__single">
        <a href={productLink}>
          <div
            className="floating-products__single-packaging"
            ref={this.packagingNode}
          >
            <div
              style={{
                height: "86%",
                position: "absolute",
                width: "100%",
              }}
            >
              <Sticker>
                <div
                  className={
                    "floating-products__single-product floating-products__single-product--stick" +
                    this.state.positionClass
                  }
                  ref={this.floatingProduct}
                  style={this.state.positionFixed ? styles : {}}
                >
                  <img src={productImage} alt={productTitle} />
                </div>
              </Sticker>
            </div>
            <img
              className="floating-products__packaging"
              src={packaging}
              alt={productTitle}
            />
          </div>
        </a>
      </div>
    )
  }
}

export default SingleFloatingProduct
