import React from "react"

const SingleProduct = ({ image, title, disclaimer }) => {
  return (
    <article
      className="single-product"
      style={{
        height: "100%",
        zIndex: 9,
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <div
        style={{
          width: "auto",
          display: "inline-block",
          margin: "auto",
          position: "relative",
          height: "100vh",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: "auto",
            margin: "auto",
            display: "block",
            maxWidth: "100vw",
          }}
        />
      </div>
      {disclaimer}
    </article>
  )
}

export default SingleProduct
