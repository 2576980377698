import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import linkImage from "../../images/link-block-image.jpg"
import linkImageSecond from "../../images/link-block-image-2.jpg"
import plus from "../../images/icons/plus.svg"
import redPlus from "../../images/red-plus.svg"
import articleThumbnail from "../../images/article-thumbnail.jpg"
import Swiper from "react-id-swiper"
import objectFitImages from "object-fit-images"
import "swiper/css/swiper.css"

const LinkBlock = props => {
  const [swiper, updateSwiper] = useState(null)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const params = {
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    slidesPerView: "auto",
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: "auto",
        spaceBetween: 30,
      },
    },
  }

  useEffect(() => {
    objectFitImages()
  })
  return (
    <div className="link-block">
      <div className="link-block__page-links">
        <div className="link-block__center-icon">
          <img src={redPlus} alt="" />
        </div>
        <div className="link-block__grid">
          <img src={linkImage} alt="" />
        </div>
        <div className="link-block__grid">
          <Link to="/products/">
            <div className="link-block__grid-text">
              <h2>lip health.</h2>
              <p>soothing solutions for discomfort and irritation.</p>
              <span className="link-block__link">
                <img src={plus} className="link-block__plus" /> more details
              </span>
            </div>
          </Link>
        </div>
        <div className="link-block__grid">
          <Link to="/products/">
            <div className="link-block__grid-text">
              <h2>lip care.</h2>
              <p>
                daily lip care that keep your lips looking great day and night.
              </p>
              <span className="link-block__link">
                <img src={plus} className="link-block__plus" />
                more details
              </span>
            </div>
          </Link>
        </div>
        <div className="link-block__grid">
          <img src={linkImageSecond} alt="" />
        </div>
      </div>
      <div className="link-block__article-links">
        <div className="swiper-container">
          <div className="swiper-navigation">
            <ul>
              <li className="ib">
                <button className="swiper-prev" onClick={goPrev}></button>
              </li>
              <li className="ib">
                <i className="icon swiper-icon"></i>
              </li>
              <li className="ib">
                <button className="swiper-next" onClick={goNext}>
                  Next
                </button>
              </li>
            </ul>
          </div>
          <Swiper {...params} getSwiper={updateSwiper}>
            <div className="swiper-slide">
              <div data-mh="slide" className="swiper-slide-wrapper">
                <Link
                  to="/winter-lips"
                  style={{ display: "block", height: "100%", width: "100%" }}
                >
                  <img src={articleThumbnail} alt="" />
                  <div className="swiper-slide__text">
                    <h2>winter lips that conquer the cold.</h2>
                    <p>
                      from natural rosy glow to sweet and soft, healthy lips add
                      a dash of colour in the depths of winter.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default LinkBlock
