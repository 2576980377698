import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
if (!global._babelPolyfill) {
  require("babel-polyfill")
}

const previousTouchMove = Symbol()
const scrolling = Symbol()
const wheelScroll = Symbol()
const touchMove = Symbol()
const touchStart = Symbol()
const keyPress = Symbol()
const onWindowResized = Symbol()
const addNextComponent = Symbol()
const scrollWindowUp = Symbol()
const scrollWindowDown = Symbol()
const setRenderComponents = Symbol()
const _isMounted = Symbol()

const _isBodyScrollEnabled = Symbol()
const disableScroll = Symbol()
const enableScroll = Symbol()

const ANIMATION_TIMER = 300
const KEY_UP = 38
const KEY_DOWN = 40
const DISABLED_CLASS_NAME = "rps-scroll--disabled"

let scrollingState = false
let timeoutId = 0

export default class ReactPageScroller extends React.Component {
  static propTypes = {
    animationTimer: PropTypes.number,
    transitionTimingFunction: PropTypes.string,
    pageOnChange: PropTypes.func,
    scrollUnavailable: PropTypes.func,
    containerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    blockScrollUp: PropTypes.bool,
    blockScrollDown: PropTypes.bool,
  }

  static defaultProps = {
    //animationTimer: 1000,
    animationTimer: 400,
    transitionTimingFunction: "ease-in-out",
    containerHeight: "100%",
    containerWidth: "100vw",
    blockScrollUp: false,
    blockScrollDown: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      componentIndex: 0,
      componentsToRenderLength: 0,
      xDown: null,
      yDown: null,
    }
    this[previousTouchMove] = null
    this[scrolling] = false
    this[_isMounted] = false
    this[_isBodyScrollEnabled] = true
  }

  wheelScrollEvent = event => {
    const container = document.querySelector(".blistex-range__product")
    if (!container) return
    if (
      container.hasAttribute("data-scroll-disabled") &&
      container.getAttribute("data-scroll-disabled") === "true" &&
      container.hasAttribute("data-product-scroll") &&
      container.getAttribute("data-product-scroll") === "false"
    ) {
      if (event.deltaY < 0) {
        this[scrollWindowUp](this.setScrollingState)
      } else if (event.deltaY > 0) {
        this[scrollWindowDown](this.setScrollingState)
      }
    }
  }

  componentDidMount = () => {
    this[_isMounted] = true

    window.addEventListener("resize", this[onWindowResized])

    document.ontouchmove = event => {
      event.preventDefault()
    }

    window.addEventListener("touchstart", this[touchStart])
    window.addEventListener("touchmove", this[touchMove])
    this._pageContainer.addEventListener("keydown", this[keyPress])

    document.addEventListener("wheel", this.wheelScrollEvent)

    let componentsToRenderLength = 0

    if (!_.isNil(this.props.children[this.state.componentIndex])) {
      componentsToRenderLength++
    } else {
      componentsToRenderLength++
    }

    this[addNextComponent](componentsToRenderLength)
  }

  componentWillUnmount = () => {
    this[_isMounted] = false

    window.removeEventListener("resize", this[onWindowResized])
    window.removeEventListener("touchstart", this[touchStart])
    window.removeEventListener("touchmove", this[touchMove])
    this._pageContainer.removeEventListener("keydown", this[keyPress])
    document.removeEventListener("wheel", this.wheelScrollEvent)
    clearTimeout(timeoutId)
  }

  goToPage = number => {
    const { pageOnChange, children } = this.props
    const { componentIndex, componentsToRenderLength } = this.state

    let newComponentsToRenderLength = componentsToRenderLength

    if (!_.isEqual(componentIndex, number)) {
      if (!_.isNil(this["container_" + number]) && !this[scrolling]) {
        this[scrolling] = true
        this._pageContainer.style.transform = `translate3d(0, ${number *
          -100}%, 0)`

        if (pageOnChange) {
          pageOnChange(number + 1)
        }

        if (
          _.isNil(this["container_" + (number + 1)]) &&
          !_.isNil(children[number + 1])
        ) {
          newComponentsToRenderLength++
        }

        setTimeout(() => {
          this.setState(
            {
              componentIndex: number,
              componentsToRenderLength: newComponentsToRenderLength,
            },
            () => {
              this[scrolling] = false
              this[previousTouchMove] = null
              scrollingState = false
            }
          )
        }, this.props.animationTimer + ANIMATION_TIMER)
      } else if (!this[scrolling] && !_.isNil(children[number])) {
        for (let i = componentsToRenderLength; i <= number; i++) {
          newComponentsToRenderLength++
        }

        if (!_.isNil(children[number + 1])) {
          newComponentsToRenderLength++
        }

        this[scrolling] = true
        this.setState(
          {
            componentsToRenderLength: newComponentsToRenderLength,
          },
          () => {
            this._pageContainer.style.transform = `translate3d(0, ${number *
              -100}%, 0)`

            if (pageOnChange) {
              pageOnChange(number + 1)
            }

            setTimeout(() => {
              this.setState({ componentIndex: number }, () => {
                this[scrolling] = false
                this[previousTouchMove] = null
                scrollingState = false
              })
            }, this.props.animationTimer + ANIMATION_TIMER)
          }
        )
      }
    }

    setTimeout(() => {
      const prange = document.querySelector(".blistex-range__product")
      prange.setAttribute("data-product-scroll", "false")
    }, 900)
  }

  render() {
    const {
      animationTimer,
      transitionTimingFunction,
      containerHeight,
      containerWidth,
    } = this.props

    return (
      <div
        style={{
          height: containerHeight,
          width: containerWidth,
          overflow: "hidden",
        }}
      >
        <div
          ref={c => (this._pageContainer = c)}
          style={{
            height: "100%",
            width: "100vw",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: "999",
            transition: `transform ${animationTimer}ms ${transitionTimingFunction}`,
          }}
          tabIndex={0}
        >
          {this[setRenderComponents]()}
        </div>
      </div>
    )
  }

  [disableScroll] = () => {
    if (this[_isBodyScrollEnabled]) {
      this[_isBodyScrollEnabled] = false
      document.body.classList.add(DISABLED_CLASS_NAME)
      document.documentElement.classList.add(DISABLED_CLASS_NAME)
    }
  };

  [enableScroll] = () => {
    if (!this[_isBodyScrollEnabled]) {
      this[_isBodyScrollEnabled] = true
      document.body.classList.remove(DISABLED_CLASS_NAME)
      document.documentElement.classList.remove(DISABLED_CLASS_NAME)
    }
  };

  [wheelScroll] = event => {
    if (event.deltaY < 0) {
      this[scrollWindowUp](this.setScrollingState)
    } else {
      this[scrollWindowDown](this.setScrollingState)
    }
  }

  getTouches(evt) {
    return evt.touches || evt.originalEvent.touches
  }

  [touchStart] = evt => {
    const firstTouch = this.getTouches(evt)[0]

    this.setState({
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY,
    })
  }

  setScrollingState = () => {
    scrollingState = false
    timeoutId = setTimeout(() => {
      const prange = document.querySelector(".blistex-range__product")
      prange.setAttribute("data-product-scroll", "false")
    }, 450)
  };

  [touchMove] = event => {
    if (!this.state.xDown || !this.state.yDown) return
    var xUp = event.touches[0].clientX
    var yUp = event.touches[0].clientY

    var xDiff = this.state.xDown - xUp
    var yDiff = this.state.yDown - yUp
    this.setScrollingState()
    if (Math.abs(xDiff) < Math.abs(yDiff)) {
      if (yDiff > 0) {
        this[scrollWindowDown](this.setScrollingState)
      } else {
        this[scrollWindowUp](this.setScrollingState)
      }
    }

    this.setState({
      xDown: null,
      yDown: null,
    })
  };

  [keyPress] = event => {
    if (_.isEqual(event.keyCode, KEY_UP)) {
      this[scrollWindowUp](this.setScrollingState)
    }
    if (_.isEqual(event.keyCode, KEY_DOWN)) {
      this[scrollWindowDown](this.setScrollingState)
    }
  };

  [onWindowResized] = () => {
    this.forceUpdate()
  };

  [addNextComponent] = componentsToRenderOnMountLength => {
    let componentsToRenderLength = 0

    if (!_.isNil(componentsToRenderOnMountLength)) {
      componentsToRenderLength = componentsToRenderOnMountLength
    }

    componentsToRenderLength = Math.max(
      componentsToRenderLength,
      this.state.componentsToRenderLength
    )

    if (componentsToRenderLength <= this.state.componentIndex + 1) {
      if (!_.isNil(this.props.children[this.state.componentIndex + 1])) {
        componentsToRenderLength++
      }
    }

    this.setState({
      componentsToRenderLength,
    })
  };

  [setRenderComponents] = () => {
    const newComponentsToRender = []

    for (let i = 0; i < this.state.componentsToRenderLength; i++) {
      if (!_.isNil(this.props.children[i])) {
        newComponentsToRender.push(
          <div
            key={i}
            ref={c => (this["container_" + i] = c)}
            style={{ height: "100%", width: "100%" }}
          >
            {this.props.children[i]}
          </div>
        )
      } else {
        break
      }
    }

    return newComponentsToRender
  };

  [scrollWindowUp] = _callback => {
    if (scrollingState !== false) return
    const prange = document.querySelector(".blistex-range__product")
    prange.setAttribute("data-product-scroll", "true")
    scrollingState = true
    if (!this[scrolling] && !this.props.blockScrollUp) {
      if (!_.isNil(this["container_" + (this.state.componentIndex - 1)])) {
        this[disableScroll]()
        this[scrolling] = true
        this._pageContainer.style.transform = `translate3d(0, ${(this.state
          .componentIndex -
          1) *
          -100}%, 0)`

        if (this.props.pageOnChange) {
          this.props.pageOnChange(this.state.componentIndex)
        }
        setTimeout(() => {
          this[_isMounted] &&
            this.setState(
              prevState => ({ componentIndex: prevState.componentIndex - 1 }),
              () => {
                this[scrolling] = false
                this[previousTouchMove] = null
                _callback()
              }
            )
        }, this.props.animationTimer + ANIMATION_TIMER)
      } else {
        this[enableScroll]()
        scrollingState = false
        if (this.props.scrollUnavailable) {
          this.props.scrollUnavailable()
        }
      }
    }
  };

  [scrollWindowDown] = _callback => {
    if (scrollingState !== false) return
    scrollingState = true
    const prange = document.querySelector(".blistex-range__product")
    prange.setAttribute("data-product-scroll", "true")
    console.log(
      "setting to true 2",
      this[scrolling],
      this.props.blockScrollDown
    )

    if (!this[scrolling] && !this.props.blockScrollDown) {
      console.log("1")
      if (!_.isNil(this["container_" + (this.state.componentIndex + 1)])) {
        console.log("2")
        this[disableScroll]()
        this[scrolling] = true
        this._pageContainer.style.transform = `translate3d(0, ${(this.state
          .componentIndex +
          1) *
          -100}%, 0)`

        if (this.props.pageOnChange) {
          this.props.pageOnChange(this.state.componentIndex + 2)
        }
        setTimeout(() => {
          this[_isMounted] &&
            this.setState(
              prevState => ({ componentIndex: prevState.componentIndex + 1 }),
              () => {
                this[scrolling] = false
                this[previousTouchMove] = null
                this[addNextComponent]()
                _callback()
              }
            )
        }, this.props.animationTimer + ANIMATION_TIMER)
      } else {
        this[enableScroll]()
        if (this.props.scrollUnavailable) {
          this.props.scrollUnavailable()
        }
        scrollingState = false
      }
    }
  }
}
