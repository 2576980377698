import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import objectFitImages from "object-fit-images"
import icon from "../../images/icons/water-drop.svg"

class HeroBanner extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    objectFitImages()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  render() {
    let heroImage
    if (this.props.britishSummerTime) {
      heroImage = this.props.data.summerHeroImageMobile.childImageSharp.fluid

      if (this.state.width > 844) {
        heroImage = this.props.data.summerHeroImage.childImageSharp.fluid
      }
    } else {
      heroImage = this.props.data.winterHeroImageMobile.childImageSharp.fluid

      if (this.state.width > 844) {
        heroImage = this.props.data.winterHeroImage.childImageSharp.fluid
      }
    }

    return (
      <>
        <section className="hero-banner">
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={
                this.props.britishSummerTime
                  ? "hero-banner__title"
                  : "hero-banner__title hero-banner__title--white"
              }
            >
              <h1>really into lips</h1>
            </div>
            <aside className="panel">
              <a href="/intensive-moisturiser">
                <div
                  className="hero-banner__product-image"
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <Img
                    fluid={this.props.data.productImage.childImageSharp.fluid}
                  />
                </div>
                <div className="panel-container">
                  <div className="hero-banner__product-title-container">
                    <img
                      className="hero-banner__product-icon"
                      src={icon}
                      alt=""
                    />
                    <div>
                      <h2 className="hero-banner__product-title hero-banner__product-title--primary">
                        intensive moisturiser
                      </h2>
                      <h2 className="hero-banner__product-title hero-banner__product-title--secondary">
                        SPF 10
                      </h2>
                    </div>
                  </div>
                  <p className="hero-banner__product-desc">
                    expertly blended with shea butter & camphor to rehydrate, and allantoin to smooth your lips all day. 
                  </p>
                </div>
              </a>
            </aside>
          </div>
        </section>
        <div className="hero-banner__image">
          <Img fluid={heroImage} style={{ height: "90vh" }} />
        </div>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        summerHeroImage: file(relativePath: { eq: "hero-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        summerHeroImageMobile: file(
          relativePath: { eq: "hero-banner-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        winterHeroImage: file(relativePath: { eq: "winter-hero-banner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        winterHeroImageMobile: file(relativePath: { eq: "mobile-hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        productImage: file(relativePath: { eq: "blistex-protect-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <HeroBanner data={data} {...props} />}
  />
)

// heroImageMobile: file(relativePath: { eq: "hero-image-mobile.jpg" }) {
//   childImageSharp {
//     fluid(maxWidth: 900) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
